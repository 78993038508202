const echosphereSettings = {
	title: "EchoSphere News",
	description: "A global news platform entirely dedicated to climate, environmental innovation, and sustainable futures. Its content is driven by citizen scientists, eco-activists, and international agencies, focusing on real-time solutions to climate change.",
	logo: "/logo.png",
	logoBig: "/logo512.png",
	headerBg: 'rgb(34,25,89)',
	footerBg: "rgb(34,25,89)",
	headerTextColor: "#fff",
	footerTextColor: "#fff",
	style: 'dramatic'
};

export default echosphereSettings;
